<template>
  <div :class="$style.auth">
    <div class="pt-5 pb-5 d-flex mt-auto">
      <img src="resources/images/logo_abda.png" alt="abdalogo" />
    </div>
    <div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center mb-auto" :class="$style.container">
      <div class="text-dark font-size-30 mb-4 text-center">Login</div>
      <b-form class="mb-4">
        <b-form-group :invalid-feedback="veeErrors.first('login')" class="mb-4">
          <b-form-input
            size="lg"
            placeholder="Digite seu login"
            v-model="credentials.login"
            id="login"
            name="login"
            data-vv-as="login"
            aria-describedby="loginErrorFeedback"
            v-validate="'required'"
            :state="veeErrors.has('login') ? false : getValidationState('login')"
            @blur.native="getValidationState('login')"
          />
        </b-form-group>

        <b-form-group :invalid-feedback="veeErrors.first('password')" class="mb-4">
          <b-form-input
            size="lg"
            placeholder="Digite sua senha"
            v-model="credentials.password"
            id="password"
            type="password"
            name="password"
            aria-describedby="passwordErrorFeedback"
            data-vv-as="senha"
            v-validate="'required'"
            :state="veeErrors.has('password') ? false : getValidationState('password')"
            @blur.native="getValidationState('password')"
            @keyup.enter="validateAndSignIn"
          />
        </b-form-group>
        <b-button
          size="lg"
          :class="$style.loginBtn"
          @click="validateAndSignIn"
          style="color: black !important"
        >Entrar</b-button>
      </b-form>
      <router-link
        :to="{ name: 'ForgotPassword' }"
        class="text-blue font-weight-bold font-size-18"
      >Esqueceu a senha?</router-link>
    </div>
    <div class="mt-auto pb-5 pt-5">
      <div class="text-gray-4 text-center">© {{ year }} Zeon Solutions. Todos os direitos reservados.</div>
    </div>
  </div>
</template>

<script>
import SignInMixin from '@/store/modules/signIn/mixin'
import { globalActions as signInActions } from '@/store/modules/signIn/actions'

export default {
  name: 'AirLogin',
  data: () => ({
    year: new Date().getFullYear()
  }),
  mixins: [SignInMixin],
  methods: {
    validateAndSignIn() {
      this.$store
        .dispatch(`${signInActions.SIGN_IN}`)
        .then((success) => {
          if (success) {
            this.$router.replace({
              name: 'Dashboard',
            })
          }
        })
        .catch(() => {})
    },
    getValidationState(fieldName) {
      const field = this.$validator.fields.find({ name: fieldName })

      if (!field) return null

      const isDirty = field.flags.dirty
      const isValid = !this.veeErrors.has(fieldName)
      return isDirty ? isValid : null
    }
  }
}
</script>

<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
